import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {NavLink} from 'react-router-dom'
import routes, {matchRouteExact} from 'routes'

import {checkIsActive} from '../../../_metronic/_helpers/RouterHelpers'

function getMenuItemActive(url, location) {
  return checkIsActive(location, url) ? 'menu-item-active' : ''
}

const displayRegionsMenuInRoutes = [routes.cities, routes.states]

const MenuRegionsSwitch = () => {
  const location = useLocation()
  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    const matchDisplayRoutes = displayRegionsMenuInRoutes.some(matchRouteExact)
    setVisibility(matchDisplayRoutes)
  }, [location])

  if (!isVisible) {
    return null
  }

  return (
    <div className="header-menu">
      <div className="menu-nav cm_switch-menu">
        <div className={`menu-item menu-item-rel ${getMenuItemActive('/states', location)}`}>
          <NavLink className="menu-link" to={routes.states.path}>
            <span className="menu-text text-uppercase">{routes.states.title}</span>
            <i className="menu-arrow" />
          </NavLink>
        </div>
        <div className={`menu-item menu-item-rel ${getMenuItemActive('/cities', location)}`}>
          <NavLink className="menu-link" to={routes.cities.path}>
            <span className="menu-text text-uppercase">{routes.cities.title}</span>
            <i className="menu-arrow" />
          </NavLink>
        </div>
      </div>
    </div>
  )
}
MenuRegionsSwitch.defaultProps = {}
MenuRegionsSwitch.propTypes = {}

export default MenuRegionsSwitch
