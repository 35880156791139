/**
 * @param {string} path
 * @param {string} title
 * @returns {{path: string, title: string}}
 */
function createRoute(path, title) {
  return {
    path: '/' + path,
    title,
  }
}

const routes = {
  regions: createRoute('cities', 'Regiões'),
  cities: createRoute('cities', 'Cidades'),
  states: createRoute('states', 'Estados'),
  companies: createRoute('companies', 'Empresas'),
  proposals: createRoute('proposals', 'Oportunidades'),
}

export const matchRoute = (route) => {
  const curPath = window.location.pathname
  return curPath.includes(route.path)
}
export const matchRouteExact = (route) => {
  const curPath = window.location.pathname
  return curPath === route.path
}

export default routes
