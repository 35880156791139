import {Circular as CircularLoading} from 'app/components'
import {useFavorite} from 'app/hooks'
import React, {useCallback, useRef, useState} from 'react'
import {ListGroup, Overlay, Popover} from 'react-bootstrap'
import {Edit, Menu, Star} from 'react-feather'
import {useHistory} from 'react-router-dom'

import * as columnUtils from '../utils'
import {FavoriteIcon, StyledPopover} from './styles'

const Row = ({id, name, state, columns, is_favorite, demographic_data, has_permission_to_edit, counters, location}) => {
  const popoverRef = useRef(null)

  const history = useHistory()

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const findDemographicInfo = useCallback(
    (key, defaultParam = 'Não Informado', label = '') => {
      const info = demographic_data?.find(({indicator}) => indicator === key)?.value
      return info ? `${info}${label}` : `${defaultParam}${label}`
    },
    [demographic_data],
  )

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'city',
    label: 'Cidade',
  })

  const goToCitieDetails = useCallback(
    (citieId) => {
      history.push(`/cities/${citieId}`)
    },
    [history],
  )

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const formatCurrency = (amount, multiply) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(multiply ? multiply * amount : amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  return (
    <tr>
      {columnUtils.shouldRenderColumn({
        columnKey: 'id',
        stateColumns: columns,
      }) && (
        <th className="align-middle" scope="row">
          {id}
        </th>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'name',
        stateColumns: columns,
      }) && (
        <td
          className="align-middle"
          style={{color: '#00428B', cursor: 'pointer', textTransform: 'uppercase', fontWeight: 'bold'}}
          onClick={() => history.push('/cities/' + id)}>
          {name}
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'state',
        stateColumns: columns,
      }) && (
        <td
          className="align-middle"
          style={{color: '#00428B', cursor: 'pointer', textTransform: 'uppercase', fontWeight: 'bold'}}
          onClick={() => history.push('/states/' + state?.id)}>
          {state?.uf}
        </td>
      )}

      {columnUtils.shouldRenderColumn({
        columnKey: 'population',
        stateColumns: columns,
      }) && (
        <td className="align-middle" style={{fontWeight: 'bold'}}>
          {formatNumber(findDemographicInfo('populacao_estimada', '0'))}
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'ideb',
        stateColumns: columns,
      }) && <td className="align-middle">{findDemographicInfo('taxa_escolarizacao')}</td>}
      {columnUtils.shouldRenderColumn({
        columnKey: 'idhm',
        stateColumns: columns,
      }) && <td className="align-middle">{findDemographicInfo('idhm')}</td>}
      {columnUtils.shouldRenderColumn({
        columnKey: 'profits',
        stateColumns: columns,
      }) && (
        <td className="align-middle" style={{textTransform: 'uppercase'}}>
          {formatCurrency(findDemographicInfo('total_receitas', '0'), 1000)}
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'expenses',
        stateColumns: columns,
      }) && (
        <td className="align-middle" style={{textTransform: 'uppercase'}}>
          {formatCurrency(findDemographicInfo('total_despesas', '0'), 1000)}
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'pib',
        stateColumns: columns,
      }) && (
        <td className="align-middle" style={{textTransform: 'uppercase'}}>
          {formatCurrency(findDemographicInfo('pib_per_capta', '0'))}
        </td>
      )}
      {/*columnUtils.shouldRenderColumn({
        columnKey: "counters.proposals.private",
        stateColumns: columns
      }) && <td className="align-middle">{formatNumber(counters?.proposals?.private || 0)}</td>*/}
      {/*columnUtils.shouldRenderColumn({
        columnKey: "counters.proposals.public",
        stateColumns: columns
      }) && <td className="align-middle">{formatNumber(counters?.proposals?.public || 0)}</td>*/}

      {columnUtils.shouldRenderColumn({
        columnKey: 'location.vocations',
        stateColumns: columns,
      }) && <td className="align-middle">{location?.vocations?.map((i) => i.description).join(', ')}</td>}

      {columnUtils.shouldRenderColumn({
        columnKey: 'location.benefits',
        stateColumns: columns,
      }) && <td className="align-middle">{location?.benefits?.map((i) => i.type.toUpperCase()).join(', ')}</td>}

      <td className="align-middle">
        <i className="fa fa-ellipsis-h" aria-hidden="true" onClick={handlePopoverClick} style={{cursor: 'pointer'}} />
        <Overlay
          show={showPopover}
          ref={popoverRef}
          target={popoverTarget}
          container={popoverRef.current}
          placement="bottom"
          rootClose={true}
          onHide={() => setShowPopover(false)}>
          <StyledPopover>
            <Popover.Content>
              <ListGroup variant="flush">
                <ListGroup.Item onClick={() => goToCitieDetails(id)}>
                  <Menu className="icon-menu" /> Ver detalhes
                </ListGroup.Item>

                <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                  <Star className="icon-menu" />
                  {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                </ListGroup.Item>

                {has_permission_to_edit && (
                  <ListGroup.Item onClick={() => history.push(`/cities/${id}/edit`)}>
                    <Edit className="icon-menu" />
                    Editar
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Popover.Content>
          </StyledPopover>
        </Overlay>
      </td>
      <td className="align-middle">
        {loadingFavorite ? (
          <CircularLoading />
        ) : (
          <FavoriteIcon
            aria-hidden="true"
            className="fa fa-star"
            isFavorite={isFavorite}
            onClick={() => handleFavoriteOpportunity()}
          />
        )}
      </td>
    </tr>
  )
}

export default Row
