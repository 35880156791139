import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {NavLink} from 'react-router-dom'
import routes from 'routes'

import {checkIsActive} from '../../../../_helpers'

const MENU_ITEMS = [routes.regions, routes.companies, routes.proposals]

function getMenuItemActive(url, location) {
  return checkIsActive(location, url) ? 'menu-item-active' : ''
}

function MenuEntry({route, layoutProps}) {
  const location = useLocation()
  return (
    <li className={`menu-item menu-item-rel ${getMenuItemActive(route.path, location)}`}>
      <NavLink className="menu-link" to={route.path}>
        <span className="menu-text text-uppercase">{route.title}</span>
        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
      </NavLink>
    </li>
  )
}

export function HeaderMenu({layoutProps}) {
  const location = useLocation()

  const {admin} = useSelector(
    ({auth}) => ({
      admin: auth.user.roles.includes('ROLE_ADMIN'),
    }),
    shallowEqual,
  )

  return (
    <>
      <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {MENU_ITEMS.map((route) => (
            <MenuEntry key={route.title} route={route} layoutProps={layoutProps} />
          ))}
        </ul>
      </div>
    </>
  )
}
